
import { Inject, Options, Vue } from "vue-property-decorator";
import FscMultiselect from "@/components/FscMultiselect.vue";
import { Mutation, namespace, State } from "s-vuex-class";
import { IBasicNamedDto } from "@/interfaces/IBasicNamedDto";
import UserService from "@/services/UserService";
import moment from "moment";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Validation from "@/components/Validation.vue";
import useRequest from "@/composable/useRequest";
import { IShortRegistrationDto } from "@/interfaces/IShortRegistrationDto";

const PublicInfoModule = namespace("public-info");

@Options({
  components: {
    Validation,
    FscMultiselect,
  },
  validations: {
    licenseClass: { required },
    theoryCourse: {},
    branch: {},
  },
})
export default class TrainingBlock extends Vue {
  public name = "TrainingBlock";

  public v$ = useVuelidate();

  private shortRegistrationsCurrent = useRequest<IShortRegistrationDto>();

  @PublicInfoModule.Action("getLicenceClasses")
  public getLicenceClasses!: (drivingSchoolId: number) => Promise<any>;

  @PublicInfoModule.Getter("getLicenceClasses")
  public licenceClasses!: any;

  @PublicInfoModule.Mutation("SET_THEORY_COURSES")
  public SET_THEORY_COURSES!: any;

  @PublicInfoModule.Action("getTheoryCourses")
  public getTheoryCourses!: (opt: { drivingSchoolId: number | undefined; licenseClass: string; branchId: null }) => Promise<any>;

  @PublicInfoModule.Getter("getTheoryCourses")
  public theoryCourses!: any;

  @PublicInfoModule.Getter("getIsLoading")
  public getIsLoading!: any;

  @PublicInfoModule.Action("getBranches")
  public getBranches!: (drivingSchoolId: number) => Promise<any>;

  @PublicInfoModule.Getter("getBranches")
  public branches!: any;

  @State("licenseClass")
  protected licenseClass!: IBasicNamedDto | null;

  @State("theoryCourse")
  protected theoryCourse!: IBasicNamedDto | null;

  @State("branch")
  protected branch!: IBasicNamedDto | null;

  @Mutation("setLicenseClass")
  protected setLicenseClass!: (payload: any) => void;

  @Mutation("setTheoryCourse")
  protected setTheoryCourse!: (payload: any) => void;

  @Mutation("setBranch")
  protected setBranch!: (payload: any) => void;

  protected get licenseClassComputed() {
    return this.licenseClass;
  }

  protected set licenseClassComputed(value: any) {
    this.setLicenseClass(value);
  }

  protected get theoryCourseComputed() {
    return this.theoryCourse;
  }

  protected set theoryCourseComputed(value: any) {
    this.setTheoryCourse(value);
  }

  protected get branchComputed() {
    return this.branch;
  }

  protected set branchComputed(value: any) {
    this.setBranch(value);
  }

  private onSelectLicenseClass(option: IBasicNamedDto | null) {
    this.theoryCourseComputed = null;
    if (!this.drivingSchoolId || !option) return;
    this.getTheoryCourses({
      drivingSchoolId: this.drivingSchoolId,
      licenseClass: option.name,
      branchId: this.branchComputed?.id,
    });
  }

  private onDeselectLicenseClass() {
    this.theoryCourseComputed = null;
    this.SET_THEORY_COURSES([]);
  }

  private onSelectBranch(option: IBasicNamedDto | null) {
    this.branchComputed = option;
    if (this.licenseClassComputed && option && option.id) {
      this.getTheoryCourses({
        drivingSchoolId: this.drivingSchoolId,
        licenseClass: this.licenseClassComputed.name,
        branchId: this.branchComputed?.id,
      });
    }
  }

  private get publicBranchesFilter() {
    if (this.theoryCourseComputed) return [this.theoryCourseComputed.branch];
    return this.branches;
  }

  public get drivingSchoolId() {
    return UserService.getDrivingSchoolId();
  }

  public mounted() {
    if (!this.drivingSchoolId) return;
    this.getLicenceClasses(this.drivingSchoolId);
    this.getBranches(this.drivingSchoolId);
    if (!this.licenseClassComputed) {
      this.currentRegistration();
    }
  }

  private async currentRegistration() {
    await this.shortRegistrationsCurrent.fetch({
      url: `/student-pre-registrations/current`,
    });

    this.getTheoryCourses({
      drivingSchoolId: this.drivingSchoolId,
      //@ts-ignore
      licenseClass: this.shortRegistrationsCurrent.data?.licenseClassEnum,
      branchId: null,
    });

    this.licenseClassComputed = {
      id: 0,
      //@ts-ignore
      name: this.shortRegistrationsCurrent.data?.licenseClassEnum,
    };

    //@ts-ignore
    this.theoryCourseComputed = this.shortRegistrationsCurrent.data?.theoryCourse;
  }

  private onRequest() {
    //@ts-ignore
    this.v$.$touch();

    //@ts-ignore
    if (this.v$.$invalid) {
      return;
    }
    if (!this.drivingSchoolId) {
      console.error("Hasn't this.drivingSchoolId");
      return;
    }

    this.$router.push({
      name: "FullyCompleteRegistration",
      params: {
        drivingSchool: this.drivingSchoolId,
      },
    });
  }

  public nameWithStartDate(option: { name: string; startDate: string; endDate: string }) {
    const startDate = moment(option.startDate).format("DD.MM");
    const endDate = moment(option.endDate).format("DD.MM.YYYY");
    return `${startDate} - ${endDate} | ${option.name}`;
  }

  @Inject({ from: "setScrollY", default: () => null })
  private setScrollY!: (value: boolean) => void;

  @Inject({ from: "scrollToPoint", default: (valueY: number) => null })
  private scrollToPoint!: (valueY: number) => void;

  public async onOpenMultiselect(ref: string, scrollY: number) {
    let newScrollY = scrollY;

    const trainingBlockHeight = document.getElementById("training-block-header")?.offsetHeight;
    if (trainingBlockHeight) {
      newScrollY += trainingBlockHeight;
    }
    await this.$nextTick(() => {
      this.scrollToPoint(newScrollY);
      setTimeout(() => {
        this.setScrollY(false);
      }, 100);
    });
  }

  public onCloseMultiselect() {
    this.setScrollY(true);
  }

  private get currentRegistrationLoading() {
    return this.shortRegistrationsCurrent.loading || this.getIsLoading;
  }

  public get theoryCoursesWithFreeSlotsOnly() {
    if (this.theoryCourses) {
      return this.theoryCourses.filter((theoryCourse: any) => theoryCourse.freeSlots > 0);
    }
    return [];
  }
}
